<template>
  <div>
    <div class="container" v-if="$can('read', 'DTMPAY')">
      <b-alert v-height-fade.appear variant="danger" :show="val" class="mb-0 mt-1">
        <div class="alert-body">
          <feather-icon icon="InfoIcon" class="mr-50" />
          Amount should be minimum 1.00/= and maximum
          {{ corrent_amount }}/=
        </div>
      </b-alert>
      <div class="d-flex justify-content-center">
        <b-card>
          <form @submit.prevent="updatetran()">
            <b-row>
              <!-- Current password -->
              <b-col cols="12 mb-1">
                <label for="example-datepicker">Donate date</label>
                <b-form-datepicker id="example-datepicker" v-model="form.tran_date" class="mb-1" required />
              </b-col>

              <!-- password -->
              <b-col cols="12 mb-1">
                <label for="amount">Donate Amount</label>
                <input class="form-control" placeholder="0.00" type="text" id="amount" v-model="form.total_amount"
                  v-on="validate(form.total_amount)" required />
              </b-col>
              <b-col cols="12" class="mb-1">
                <label for="tran">Pay Type</label>
                <select class="custom-select" v-model="form.pay_type" id="tran">
                  <option value="" selected>Select Transactions</option>
                  <option :value="d.value" v-for="(d, index) in pay_type" :key="index">
                    {{ d.key }}
                  </option>
                </select>
              </b-col>
              <b-col cols="12 mb-1">
                <!-- confirm password -->
                <label for="textarea-default">Description</label>
                <b-form-textarea id="textarea-default" placeholder="Donate Description" v-model="form.tran_details"
                  rows="3" />
              </b-col>
              <!-- confirm password -->
              <b-col cols="12 mb-1">
                <label for="ref">Name</label>
                <input class="form-control" placeholder="Name" type="text" id="ref" v-model="form.remark" required />
              </b-col>
              <b-col cols="12 mb-1">
                <label for="ref">Transaction ID</label>
                <input class="form-control" placeholder="Transaction ID" type="text" id="ref"
                  v-model="form.gateway_tran_id" required />
              </b-col>

              <!-- reset button -->
              <b-col cols="12">
                <b-overlay :show="show" rounded="sm">
                  <b-button variant="primary" type="submit" :disabled="val" @click="show = true">
                    Credit
                  </b-button>
                </b-overlay>
              </b-col>
            </b-row>
          </form>
        </b-card>
      </div>
    </div>
    <div v-if="items">
      <b-table small :fields="fields" :items="items" responsive="sm">
        <!-- A virtual column -->
        <template #cell(index)="data">
          {{ data.index + 1 }} /
          <b-badge pill>
            <span>{{ data.item.id }}</span>
          </b-badge>
        </template>

        <!-- A custom formatted column -->
        <template #cell(gateway_tran_id)="data">
          <!-- <b-badge variant="light-primary">
          {{ data.value }}
        </b-badge> -->
          <b-badge pill variant="primary" v-if="data.item.is_auth">
            <feather-icon icon="HashIcon" class="mr-25" />
            <span>{{ data.value }}</span>
          </b-badge>
          <b-badge href="#" variant="danger" v-else>
            <feather-icon icon="HashIcon" class="mr-25" />
            <span>{{ data.value }}</span>
          </b-badge>
        </template>
        <!-- A custom formatted column -->
        <template #cell(tran_val)="data">
          <a @click="
            openPortal(`https://api.yfbd.org/dtm/invoice/${data.item.id}/`)
          ">
            <b-badge variant="success">
              <feather-icon icon="PrinterIcon" />
            </b-badge>
          </a>
        </template>

        <!-- A custom formatted column -->
        <template #cell(Popularity)="data">
          <b-progress :value="data.value.value" max="100" :variant="data.value.variant" striped />
        </template>
        <!-- A custom formatted column -->
        <template #cell(pay_type)="data">
          <b-badge variant="dark" class="badge-glow" v-if="data.value == 'Cash'">
            {{ data.value }}
          </b-badge>
          <b-badge class="badge-glow" v-else>
            {{ data.value }}
          </b-badge>
        </template>

        <template #cell(is_auth)="data">
          <b-badge variant="success" v-if="data.value">
            <feather-icon icon="ShieldIcon" class="mr-25" />
            <span> Verified </span>
          </b-badge>
          <b-badge href="#" @click="postauth(data.item.id)" variant="danger" v-else>
            <feather-icon icon="ShieldOffIcon" class="mr-25" />
            <span>Unverified</span>
          </b-badge>
        </template>

        <!-- A virtual composite column -->
        <template #cell(total_amount)="data">
          <b-badge pill variant="primary">
            {{ "৳" + data.value }}
          </b-badge>
        </template>

        <!-- Optional default data cell scoped slot -->
        <template #cell(tran_date)="data">
          <b-badge pill variant="dark">{{ data.value }}</b-badge>
        </template>
        <!-- Optional default data cell scoped slot -->
        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>
    </div>
    <!-- <button type="submit" class="btn btn-success" @click="generateReport()">Print</button> -->
  </div>
</template>

<script>
import {
  BTable,
  BProgress,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BFormTextarea,
  BFormSelect,
  BFormDatepicker,
  BAlert,
  BOverlay,
} from "bootstrap-vue";
// import PersonalDesh from "../extensions/card-statistic/CardStatisticsGroup.vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";

export default {
  components: {
    BTable,
    BProgress,
    BBadge,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    // PersonalDesh,
    BButton,
    BModal,
    VBModal,
    BFormTextarea,
    BFormSelect,
    BFormDatepicker,
    BAlert,
    BOverlay,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      print: {},
      show: false,
      val: false,
      auth: false,
      pin: "",
      corrent_amount: 0,
      accdata: {},
      pay_type: [
        { value: "Cash", key: "Cash" },
        { value: "Bkash", key: "Bkash" },
        { value: "Rocket", key: "Rocket" },
        { value: "Nagad", key: "Nagad" },
        { value: "Bank Transfer", key: "Bank Transfer" },
        { value: "Others", key: "Others" },
      ],
      form: {
        gateway_tran_id: "",
        tran_date: "",
        val_id: null,
        total_amount: 0,
        tran_details: "",
        remark: "",
        ip_address: "",
        pay_type: "Cash",
      },
      options: [{ value: null, text: "Please select an option", balance: 0 }],
      items: [],
      fields: [
        // A virtual column that doesn't exist in items
        "index",
        // A column that needs custom formatting
        { key: "remark", label: "Name" },
        { key: "tran_details", label: "Descriptions" },
        { key: "tran_date", label: "Transaction Date" },
        { key: "gateway_tran_id", label: "Transaction ID" },
        "pay_type",
        // A regular column
        // A regular column
        { key: "is_auth", label: "Auth Status" },
        // A virtual column made up from two fields
        { key: "total_amount", label: "Total Amount" },
        { key: "tran_val", label: "Action" },
      ],
    };
  },
  watch: {
    // whenever question changes, this function will run
  },
  computed: {},
  methods: {
    openPortal(link) {
      this.windowRef = window.open(
        link,
        "Money Recept - Print",
        "width=800,height=1200,left=200,top=200"
      );
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    validate: function (amount) {
      if (amount != 0) {
        this.val = false;
      } else {
        this.val = true;
      }
    },
    corrent(select) {
      let that = this;
      this.$http
        .get(`/dtm/reg/pay/singleAccount/${that.form.val_id || "no"}/`)
        .then((response) => {
          that.corrent_amount = response.data.balance;
        })
        .catch((error) => {
          console.log(error);
          that.corrent_amount = 0;
        });
    },
    access() {
      if (this.pin) {
        this.$http
          .post(`/dtm/debit/auth/`, {
            pin: this.pin,
          })
          .then((response) => {
            this.auth = response.data.is_auth;
            if (response.data.message == false) {
              let variant = "success";
              this.$bvToast.toast("PIN Verified ", {
                title: `Access Success`,
                variant,
                solid: true,
              });
            } else {
              let variant = "warning";
              this.$bvToast.toast("PIN Incorrect", {
                title: `Access Faild`,
                variant,
                solid: true,
              });
            }
          })
          .catch((error) => console.log(error));
      } else {
        let variant = "danger";
        this.$bvToast.toast("PIN is Required", {
          title: `Access Faild`,
          variant,
          solid: true,
        });
      }
    },

    updatetran() {
      const that = this;
      var userdata = JSON.parse(localStorage.getItem("userData"));
      console.log(userdata);
      console.log(that.form.total_amount);
      if (!that.val) {
        if (that.form.total_amount != 0) {
          that.$http
            .post(`/dtm/sundy/pay/publicdonate/1/`, that.form)
            .then((response) => {
              this.clean();
              let variant = "success";
              this.$bvToast.toast(response.data.message, {
                title: `Success`,
                variant,
                solid: true,
              });
              console.log(response);
            })
            .catch((error) => {
              this.show = false;
              let variant = "danger";
              this.$bvToast.toast("Transaction Failed", {
                title: `Error`,
                variant,
                solid: true,
              });
              console.log(error);
            });
        } else {
          this.show = false;
          let variant = "danger";
          this.$bvToast.toast("Donate Amount is Required", {
            title: `Error`,
            variant,
            solid: true,
          });
        }
      } else {
        this.show = false;
        let variant = "danger";
        this.$bvToast.toast("Transaction Amount is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    pre() {
      this.$http
        .get(`/dtm/debit/account/`)
        .then((response) => {
          this.accdata = response.data;
          var predata = response.data;
          predata.map((data) => {
            this.options.push({
              value: data.accountNo,
              text: `${data.accountName} (Account No: ${data.accountNo})`,
              balance: data.balance,
            });
          });
        })
        .catch((error) => console.log(error));
    },

    gettran() {
      this.$http
        .get(`/dtm/sundy/pay/donatelist/1/`)
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => console.log(error));
      // this.$http
      //   .get(`/dtm/reg/pay/userDash/${this.user}/`)
      //   .then((response) => {
      //     var data = response.data.account;
      //     this.statisticsItems[0].title =
      //       data.total_tran + "/" + data.this_month_tran;
      //     this.statisticsItems[1].title =
      //       data.total_reg + "/" + data.this_month_reg;
      //     this.statisticsItems[2].title = `৳` + data.this_month_amount;
      //     this.statisticsItems[3].title = `৳` + data.panding_amount;
      //     this.total = `৳` + data.total_amount;
      //   })
      //   .catch((error) => console.log(error));
    },
    clean() {
      this.show = false;
      this.form.total_amount = 0;
      this.form.gateway_tran_id = "";
      this.form.val_id = null;
      this.form.tran_date = "";
      this.form.tran_details = "";
      this.form.remark = "";
      this.corrent_amount = 0;
      this.options = [
        { value: null, text: "Please select an option", balance: 0 },
      ];
      this.pre();
      this.gettran();
    },

    async getip(ip) {
      await axios
        .get(`https://get.geojs.io/v1/ip/geo/${ip}.json`)
        .then((response) => {
          // this.ip = response.data;
        })
        .catch((error) => console.log(error));
    },
    async getnewip() {
      await axios
        .get(`https://get.geojs.io/v1/ip.json`)
        .then((response) => {
          this.form.ip_address = response.data.ip;
          console.log(response.data.ip);
        })
        .catch((error) => console.log(error));
    },
  },
  created() {
    this.getnewip();
    this.gettran();
    this.pre();
    // var userdata = JSON.parse(localStorage.getItem("userData"));
  },
};
</script>
<style>
/* @media print {
  body,
  page {
    margin: 0px;
    box-shadow: 0;
  }
} */
</style>
