<template>
  <div>
    <div class="row">
      <div v-if="is_result" class="col-12">
        <!-- success -->
        <b-alert variant="success" show>
          <h4 class="alert-heading">Success</h4>
          <div class="alert-body">
            <div class="row">
              <div class="col-12">
                <span>Message: {{ result.message }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <span> DTM ID: {{ result.dtm_id }}</span>
              </div>
              <!-- <div class="col-12">
                <span> Full Name: {{ result.fullName }}</span>
              </div>
              <div class="col-12">
                <span> Mobile Number: {{ result.mobile1 }}</span>
              </div> -->
              <div class="col-12" v-if="result.sms_response">
                <span> SMS Status: </span>
                <b
                  style="color: red"
                  v-if="result.sms_response.api_response_code == 400"
                >
                  {{ result.sms_response.api_response_message }}</b
                >
                <b v-else> {{ result.sms_response.api_response_message }}</b>
              </div>
            </div>
          </div>
        </b-alert>
      </div>
      <div class="col-12 mb-1">
        <form class="input-group" @submit.prevent="gettran()">
          <!-- basic search -->
          <input
            class="form-control"
            placeholder="+88018"
            list="number-list"
            type="text"
            v-model="customer_id"
            @keyup="getdatasundy()"
          />
          <datalist id="number-list">
            <option
              :value="d.customer_id"
              v-for="(d, index) in predata"
              :key="index"
            >
              {{ d.fullName }}
            </option>
          </datalist>
          <div class="input-group-append">
            <button
              type="button"
              class="btn btn-outline-primary"
              @click="gettran()"
            >
              Search !
            </button>
          </div>
        </form>
      </div>
      <div class="col-12 mb-1">
        <input
          class="form-control"
          placeholder="Full Name | Date Of Birth"
          type="text"
          v-model="fullName"
          readonly
        />
        <hr />
        <select class="custom-select" v-model="selected">
          <option value="null" selected>Select Transactions</option>
          <option :value="d.value" v-for="(d, index) in options" :key="index">
            {{ d.text }}
          </option>
        </select>
      </div>
    </div>
    <!-- <regsearch /> -->
    <br />
    <b-form-select v-model="selected" :options="options" :select-size="20" />

    <div class="">
      <button
        type="button"
        class="btn btn-outline-success mr-1 mt-1"
        @click="updatetran()"
        :disabled="buttonStatus == false"
      >
        Cash Pay !
      </button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.modal-select2
        variant="outline-primary"
        class="mr-1 mt-1"
        :disabled="buttonStatus == false"
      >
        NEW Cash Pay !
      </b-button>
      <button
        type="button"
        class="btn btn-outline-danger mr-1 mt-1"
        @click="cleandata()"
      >
        Clean !
      </button>
    </div>
    <!-- select 2 demo -->
    <b-modal
      id="modal-select2"
      title="New Cash Payment"
      ok-title="Pay"
      @ok="newcashtran()"
      cancel-variant="outline-secondary"
    >
      <b-form @submit.prevent="newcashtran()">
        <div class="text-right">
          <b-badge variant="success" class="badge-glow">
            {{ total_amount }}BDT
          </b-badge>
        </div>
        <b-form-group label="Membership Fee" label-for="fee">
          <b-form-input
            id="fee"
            placeholder="200.00"
            value="200.00"
            type="number"
            readonly
          />
        </b-form-group>
        <div class="mb-1">
          <label for="donate_type">Donate for</label>
          <select
            class="custom-select"
            v-model="formdata.donate_type"
            id="donate_type"
          >
            <option value="0" selected>Organization Recommend</option>
            <option value="1">Food</option>
            <option value="2">Education</option>
            <option value="3">Treatment</option>
            <option value="4">Zakat Fund</option>
            <option value="5">Emergency Relief</option>
            <option value="6">Others</option>
          </select>
        </div>
        <b-form-group label="Extra Donation" label-for="donate">
          <b-form-input
            id="donate"
            type="number"
            placeholder="0"
            v-model="formdata.donate_amount"
          />
        </b-form-group>
        <div>
          <label for="reg-datepicker">Choose Trx date</label>
          <b-form-datepicker
            id="reg-datepicker"
            v-model="formdata.tran_date"
            class="mb-1"
            required
          />
        </div>
        <div class="mb-1" v-if="$can('read', 'DTMPAY')">
          <label for="tran">Pay Type</label>
          <select class="custom-select" v-model="formdata.pay_type" id="tran">
            <option value="" selected>Select Transections</option>
            <option
              :value="d.value"
              v-for="(d, index) in pay_type"
              :key="index"
            >
              {{ d.key }}
            </option>
          </select>
        </div>

        <b-form-group
          :label="pay_m ? 'TrxID' : 'Money Recept Number'"
          label-for="money"
        >
          <b-form-input
            id="money"
            type="text"
            placeholder="########"
            v-model="formdata.moneyrecept"
          />
        </b-form-group>
        <div v-if="$can('read', 'DTMPAY')">
          <b-form-group label="From Number" label-for="fromnumber" v-if="pay_m">
            <b-form-input
              id="fromnumber"
              type="text"
              list="from-list"
              placeholder="00000000000"
              v-model="formdata.number"
            />
            <datalist id="from-list">
            <option :value="data.data.mobile1">
              {{ data.data.fullName }}
            </option>
          </datalist>
          </b-form-group>
          <b-form-group label="To Number" label-for="tonumber" v-if="pay_m">
            <b-form-input
              id="tonumber"
              type="text"
              list="to-list"
              placeholder="00000000000"
              v-model="formdata.account"
            />
            <datalist id="to-list">
              <option value="+8801713222343"></option>
              <option value="+8801873708000"></option>
              <option value="+8801990003339"></option>
            </datalist>
          </b-form-group>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
   
<script>
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BBadge,
  BAlert,
  BFormDatepicker,
} from "bootstrap-vue";

import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "axios";

// import regsearch from "./components/reg-cash-search";

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormGroup,
    BBadge,
    vSelect,
    // regsearch,
    BFormSelect,
    BAlert,
    BFormDatepicker,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      is_result: false,
      result: {},
      customer_id: "",
      fullName: "",
      data: {},
      predata: [],
      status: null,
      selected: null,
      options: [],
      pay_type: [
        { value: "Cash", key: "Cash" },
        { value: "Bkash", key: "Bkash" },
        { value: "Rocket", key: "Rocket" },
        { value: "Nagad", key: "Nagad" },
        { value: "Bank Transfer", key: "Bank Transfer" },
        { value: "Others", key: "Others" },
      ],
      geo: {
        ip: "172.16.0.1",
      },
      formdata: {
        customer_amount: 200.0,
        donate_type: "0",
        donate_amount: 0,
        moneyrecept: "",
        number: "+880",
        account: "",
        pay_type: "Cash",
        tran_date: "",
      },
    };
  },
  computed: {
    buttonStatus() {
      const that = this;
      if (that.status == true) {
        return true;
      } else if (that.status == false) {
        return false;
      } else {
        return false;
      }
    },
    pay_m() {
      if (this.formdata.pay_type == "Cash") {
        return false;
      } else {
        return true;
      }
    },
    total_amount() {
      if (this.formdata.donate_amount) {
        if (this.formdata.donate_amount < 0) {
          return parseFloat(this.formdata.customer_amount);
        } else {
          return (
            parseFloat(this.formdata.customer_amount) +
            parseFloat(this.formdata.donate_amount)
          );
        }
      } else {
        return parseFloat(this.formdata.customer_amount);
      }
    },
  },
  methods: {
    newcashtran() {
      const that = this;
      var userdata = JSON.parse(localStorage.getItem("userData"));
      console.log(userdata);

      if (!that.customer_id == "" && !that.formdata.moneyrecept == "") {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Pay it!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm() {
            that.$http
              .post(`/dtm/newcash/pay/${userdata.id}/`, {
                moneyrecept: that.formdata.moneyrecept,
                account: that.formdata.account,
                number: that.formdata.number,
                customer_id: that.customer_id,
                tran_val: 2,
                tran_date: that.formdata.tran_date,
                donate_type: that.formdata.donate_type,
                pay_type: that.formdata.pay_type,
                total_amount: that.total_amount,
                customer_amount: that.formdata.customer_amount,
                donate_amount: that.formdata.donate_amount,
                userName: userdata.username,
                ip_address: that.geo.ip,
                remark: `Cash Payment successfull by ${userdata.username}`,
                message: `Cash Payment successfull by ${userdata.username}`,
              })
              .then((response) => {
                console.log(response);
                that.result = response.data.result;
                that.is_result = true;
              })
              .catch((error) => {
                // this.$swal.showValidationMessage(`Request failed:  ${error}`);
                console.log(error);
              });
          },
        }).then((result) => {
          if (result.value) {
            this.$swal({
              icon: "success",
              title: "Created!",
              text: "Payment has been Created.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            that.cleandata();
          }
        });
      } else {
        let variant = "danger";
        this.$bvToast.toast("Cutomer ID and Money Recept Number is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    updatetran() {
      const that = this;
      var userdata = JSON.parse(localStorage.getItem("userData"));
      console.log(userdata);
      console.log(that.selected);

      if (!that.selected == "") {
        this.$swal({
          title: "Enter Money Recept Number",
          input: "text",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Pay",
          showLoaderOnConfirm: true,
          preConfirm(number) {
            that.$http
              .post(`/dtm/reg/pay/${userdata.id}/${that.selected}/`, {
                moneyrecept: number,
                userName: userdata.username,
                message: `Cash Payment successfull by ${userdata.username}`,
              })
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                // this.$swal.showValidationMessage(`Request failed:  ${error}`);
                console.log(error);
              });
          },
        }).then((result) => {
          if (result.value) {
            this.$swal({
              title: `Payment Success`,
              // imageUrl: result.value.avatar_url,
              customClass: { confirmButton: "btn btn-primary" },
            });
            that.cleandata();
          }
        });
      } else {
        let variant = "danger";
        this.$bvToast.toast("Transaction Id is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    cleandata() {
      this.customer_id = "";
      this.fullName = "";
      this.data = {};
      this.selected = null;
      this.options = [];
      this.formdata.moneyrecept = "";
      this.formdata.number = "";
      this.formdata.account = "";
      this.formdata.tran_date = "";
      this.status = null;
      this.formdata.pay_type = "Cash";
      this.formdata.donate_amount = 0;
      this.getdatasundy();
    },
    gettran() {
      const that = this;
      if (!this.customer_id == "") {
        this.$http
          .get(`/dtm/reg/pay/regtran/${this.customer_id}/`)
          .then((response) => {
            if (response.data.message) {
              that.cleandata();
              let variant = "danger";
              this.$bvToast.toast("User Not Found", {
                title: `Error`,
                variant,
                solid: true,
              });
            } else {
              this.data = response.data;
              this.status = true;
              this.fullName =
                response.data.data.fullName +
                " | " +
                response.data.data.dateOfBirth;
              this.options = [];
              for (let i = 0; i < this.data.trans.length; i++) {
                let datanew = {
                  value: this.data.trans[i].id,
                  text: `Tran Id: ${that.data.trans[i].id} ----- PayType: ${that.data.trans[i].pay_type} | Total Amount: ${that.data.trans[i].total_amount}BDT | Registration Fee: ${that.data.trans[i].customer_amount}BDT | Extra Donation: ${that.data.trans[i].donate_amount}BDT | Payment status: ${that.data.trans[i].status} | Created: ${that.data.trans[i].created}  `,
                };
                this.options.push(datanew);
              }
            }
          })
          .catch((error) => console.log(error));
      } else {
        let variant = "danger";
        this.$bvToast.toast("Pls Enter Customer Id", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    getdatasundy() {
      this.$http
        .get(`/dtm/new/reg/${this.customer_id || 0}/`)
        .then((response) => {
          this.predata = response.data;
        })
        .catch((error) => console.log(error));
    },
    getip() {
      axios
        .get("https://get.geojs.io/v1/ip/geo.json")
        .then((response) => {
          this.geo = response.data;
        })
        .catch((error) => console.log(error));
    },
  },
  created() {
    this.getdatasundy();
    this.getip();
  },
};
</script>