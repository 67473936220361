<template>
  <div>
    <div class="row">
      <div v-if="is_result" class="col-12">
        <!-- success -->
        <b-alert variant="success" show>
          <h4 class="alert-heading">Success</h4>
          <div class="alert-body">
            <div class="row">
              <div class="col-12">
                <span>Message: {{ result.message }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <span> DTM ID: {{ result.dtm_id }}</span>
              </div>
              <!-- <div class="col-12">
                <span> Full Name: {{ result.fullName }}</span>
              </div>
              <div class="col-12">
                <span> Mobile Number: {{ result.mobile1 }}</span>
              </div> -->
              <div class="col-12" v-if="result.sms_response">
                <span> SMS Status: </span>
                <b style="color: red" v-if="result.sms_response.api_response_code == 400">
                  {{
                      result.sms_response.api_response_message ||
                      result.sms_response.message
                  }}
                </b>
                <b v-else>
                  {{
                      result.sms_response.api_response_message ||
                      result.sms_response.message
                  }}</b>
              </div>
            </div>
          </div>
        </b-alert>
      </div>
      <div class="col-12 mb-1">
        <form class="input-group" @submit.prevent="gettran()">
          <!-- basic search -->
          <input class="form-control" placeholder="DTM ID 20001" list="dtm-list" type="text" v-model="dtmid"
            @keyup="getdata()" />
          <datalist id="dtm-list">
            <option :value="d.dtm_id" v-for="(d, index) in predata" :key="index">
              {{ d.dtm_profile.fullName }}
            </option>
          </datalist>
          <div class="input-group-append">
            <button type="button" class="btn btn-outline-primary" @click="gettran()">
              Search !
            </button>
          </div>
        </form>
      </div>
      <div class="col-12 mb-1">
        <div class="row">
          <div class="col-12 col-lg-6 mb-1">
            <input class="form-control" placeholder="Full Name" type="text" v-model="fullName" readonly />
          </div>
          <div class="col-12 col-lg-3 mb-1">
            <input class="form-control" placeholder="Mobile Number" type="text" v-model="mobile1" readonly />
          </div>
          <div class="col-12 col-lg-3 mb-1">
            <input class="form-control" placeholder="Date of Birth" type="text" v-model="dateOfBirth" readonly />
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <input class="form-control" placeholder="Account Status" type="text" v-model="statusChack" readonly />
          </div>
          <div class="col-8 col-lg-3 mb-1">
            <input class="form-control" placeholder="Opening Date" type="text" v-model="opening_date" readonly />
          </div>
          <div class="col-4 col-lg-3">
            <a @click="sendsms('sendDtmID')">
              <b-badge pill variant="primary" class="mr-1" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.v-primary title="Send for DTM ID">
                <feather-icon icon="MailIcon" />
              </b-badge>
            </a>
            <a @click="sendsms('sendDueSms')">
              <b-badge pill variant="danger" class="mr-1" v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-tooltip.hover.v-primary
                title="Send for DUE Message">
                <feather-icon icon="MailIcon" />
              </b-badge>
            </a>
            <a @click="sendsms('sendPayInfoSms')">
              <b-badge pill variant="warning" class="mr-1" v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-tooltip.hover.v-primary
                title="Send for Payment Info Message">
                <feather-icon icon="MailIcon" />
              </b-badge>
            </a>
            <a @click="
              openBio(`https://api.yfbd.org/dtm/getinfo/${dtmid}/`)
            ">
              <b-badge pill variant="info" v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-tooltip.hover.v-primary
                title="View full Information">
                <feather-icon icon="FileTextIcon" />
              </b-badge>
            </a>
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <input class="form-control" placeholder="Free Month" type="text" v-model="free_month" readonly />
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <input class="form-control" placeholder="Present Address" type="text" :value="paddress" readonly />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mb-1 input-group input-group-merge">
            <div class="input-group-prepend">
              <div class="input-group-text">Panding Fee</div>
            </div>
            <input class="form-control" placeholder="Panding Subcription Fee" type="number" v-model="panding_amount"
              readonly />
            <div class="input-group-append">
              <div class="input-group-text">৳</div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-1 input-group input-group-merge">
            <div class="input-group-prepend">
              <div class="input-group-text">Advance Fee</div>
            </div>
            <input class="form-control" placeholder="Advance Subcription Fee" type="number" v-model="advance_amount"
              readonly />
            <div class="input-group-append">
              <div class="input-group-text">৳</div>
            </div>
          </div>
        </div>

        <!-- <div v-if="$can('read', 'DTMPAY')">
          <hr />

          <select
            class="custom-select"
            v-model="selected"
            v-if="$can('read', 'DTMPAY')"
          >
            <option value="null" selected>Select Transactions</option>
            <option :value="d.value" v-for="(d, index) in options" :key="index">
              {{ d.text }}
            </option>
          </select>
        </div> -->
      </div>
    </div>
    <!-- <regsearch /> -->
    <div v-if="$can('read', 'DTMPAY')">
      <hr />
      <!-- <b-form-select v-model="selected" :options="options" :select-size="20" /> -->
      <div v-show="data.trans">
        <b-table small :fields="fields" :items="data.trans" responsive="sm">
          <!-- A virtual column -->
          <template #cell(index)="data">
            {{ data.index + 1 }} /
            <b-badge pill>
              <span>{{ data.item.id }}</span>
            </b-badge>
          </template>

          <!-- A custom formatted column -->
          <template #cell(gateway_tran_id)="data">
            <!-- <b-badge variant="light-primary">
          {{ data.value }}
        </b-badge> -->
            <b-badge pill variant="primary" v-if="data.item.is_auth">
              <feather-icon icon="HashIcon" class="mr-25" />
              <span>{{ data.value }}</span>
            </b-badge>
            <b-badge variant="danger" v-else>
              <feather-icon icon="HashIcon" class="mr-25" />
              <span>{{ data.value }}</span>
            </b-badge>
          </template>
          <!-- A custom formatted column -->

          <!-- A custom formatted column -->
          <template #cell(pay_type)="data">
            <b-badge variant="dark" class="badge-glow" v-if="data.value == 'Cash'">
              {{ data.value }}
            </b-badge>
            <b-badge class="badge-glow" v-else>
              {{ data.value }}
            </b-badge>
          </template>

          <!-- A virtual composite column -->
          <template #cell(total_amount)="data">
            <b-badge pill variant="primary">
              {{ "৳" + data.value }}
            </b-badge>
          </template>

          <!-- Optional default data cell scoped slot -->
          <template #cell(tran_date)="data">
            <b-badge pill variant="dark">{{ data.value }}</b-badge>
          </template>
          <!-- Optional default data cell scoped slot -->
          <template #cell(pay_status)="data">
            <a @click="
              openPortal(`https://api.yfbd.org/dtm/invoice/${data.item.id}/`)
            ">
              <b-badge variant="success">
                <feather-icon icon="PrinterIcon" />
              </b-badge>
            </a>
          </template>
          <template #cell()="data">
            {{ data.value }}
          </template>
        </b-table>
      </div>
    </div>

    <div class="">
      <button type="button" class="btn btn-outline-success mr-1 mt-1" @click="updatetran()" hidden>
        Cash Pay !
      </button>

      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-select23 variant="outline-primary"
        class="mr-1 mt-1" :disabled="buttonStatus == false">
        NEW Cash Pay !
      </b-button>
      <button type="button" class="btn btn-outline-danger mr-1 mt-1" @click="cleandata()">
        Clean !
      </button>
    </div>
    <!-- select 2 demo -->
    <b-modal id="modal-select23" title="New Cash Payment" ok-title="Pay" @ok="newcashtran()"
      cancel-variant="outline-secondary">
      <b-form @submit.prevent="newcashtran()">
        <div class="text-right">
          <b-badge variant="success" class="badge-glow">
            {{ total_amount }}BDT
          </b-badge>
        </div>
        <div class="row">
          <div class="col-4">
            <label for="sb-inline" class="mr-1">Month</label>
            <!-- <br /> -->
            <b-form-spinbutton id="sb-inline" v-model="month" v-on="dtm_month()" />
          </div>
          <div class="col-8">
            <label for="fee">DTM Amount</label>
            <div class="input-group mb-1">
              <!-- basic search -->
              <input class="form-control" id="fee" placeholder="300.00" :value="formdata.dtm_amount" type="number"
                readonly />
              <div class="input-group-append">
                <button type="button" class="btn btn-outline-danger" @click="dtmreset()">
                  Reset !
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-1">
          <label for="donate_type">Donate for</label>
          <select class="custom-select" v-model="formdata.donate_type" id="donate_type">
            <option value="0" selected>Organization Recommend</option>
            <option value="1">Food</option>
            <option value="2">Education</option>
            <option value="3">Treatment</option>
            <option value="4">Zakat Fund</option>
            <option value="5">Emergency Relief</option>
            <option value="6">Others</option>
          </select>
        </div>
        <b-form-group label="Extra Donation" label-for="donate">
          <b-form-input id="donate" type="number" placeholder="0" v-model="formdata.donate_amount" />
        </b-form-group>
        <div>
          <label for="dtm-datepicker">Choose Trx date</label>
          <b-form-datepicker id="dtm-datepicker" v-model="formdata.tran_date" class="mb-1" required />
        </div>
        <div class="mb-1" v-if="$can('read', 'DTMPAY')">
          <label for="tran">Pay Type</label>
          <select class="custom-select" v-model="formdata.pay_type" id="tran">
            <option value="" selected>Select Transections</option>
            <option :value="d.value" v-for="(d, index) in pay_type" :key="index">
              {{ d.key }}
            </option>
          </select>
        </div>

        <b-form-group :label="pay_m ? 'TrxID' : 'Money Recept Number'" label-for="money">
          <b-form-input id="money" type="text" placeholder="########" v-model="formdata.moneyrecept" />
        </b-form-group>
        <div v-if="$can('read', 'DTMPAY')">
          <b-form-group label="From Number" label-for="fromnumber" v-if="pay_m">
            <b-form-input id="fromnumber" type="text" list="from-list" placeholder="00000000000"
              v-model="formdata.number" />
            <datalist id="from-list">
              <option :value="data.dtm_profile.mobile1">
                {{ data.dtm_profile.fullName }}
              </option>
            </datalist>
          </b-form-group>
          <b-form-group label="To Number" label-for="tonumber" v-if="pay_m">
            <b-form-input id="tonumber" type="text" list="to-list" placeholder="00000000000"
              v-model="formdata.account" />
            <datalist id="to-list">
              <option value="+8801713222343"></option>
              <option value="+8801873708000"></option>
              <option value="+8801990003339"></option>
            </datalist>
          </b-form-group>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
   
<script>
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormSpinbutton,
  BBadge,
  BRow,
  BCol,
  BFormCheckbox,
  BAlert,
  BFormDatepicker,
  BTable,
  VBTooltip,
} from "bootstrap-vue";

import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "axios";

// import regsearch from "./components/reg-cash-search";

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSpinbutton,
    BBadge,
    vSelect,
    BRow,
    BCol,
    BFormCheckbox,
    // regsearch,
    BFormSelect,
    BAlert,
    BFormDatepicker,
    BTable,
  },
  directives: {
    "b-modal": VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      paddress: "",
      is_result: false,
      result: {},
      dtmid: "",
      fullName: "",
      dateOfBirth: "",
      mobile1: "",
      opening_date: "",
      free_month: "",
      panding_amount: "",
      advance_amount: "",
      month: 0,
      data: {},
      status: null,
      predata: [],
      selected: null,
      options: [],
      pay_type: [
        { value: "Cash", key: "Cash" },
        { value: "Bkash", key: "Bkash" },
        { value: "Rocket", key: "Rocket" },
        { value: "Nagad", key: "Nagad" },
        { value: "Bank Transfer", key: "Bank Transfer" },
        { value: "Others", key: "Others" },
      ],
      geo: {
        ip: "172.16.0.1",
      },
      formdata: {
        dtm_amount: 0,
        donate_type: "0",
        donate_amount: 0,
        moneyrecept: "",
        account: "",
        number: "+880",
        pay_type: "Cash",
        tran_date: "",
      },
      fields: [
        // A virtual column that doesn't exist in items
        "index",
        // A column that needs custom formatting
        // { key: "dtm_id", label: "DTM ID" },
        { key: "tran_date", label: "Transaction Date" },
        "pay_type",
        // A regular column
        { key: "gateway_tran_id", label: "Tnx/Money Recept" },
        // A regular column
        { key: "dtm_amount", label: "DTM Amount" },
        { key: "donate_amount", label: "Donate Amount" },
        // A virtual column made up from two fields
        { key: "total_amount", label: "Total Amount" },
        { key: "created_by", label: "Created By" },
        { key: "pay_status", label: "Action" },
      ],
    };
  },
  computed: {
    statusChack() {
      const that = this;
      if (that.status == true) {
        return "Active";
      } else if (that.status == false) {
        return "Inactive";
      } else {
        return "";
      }
    },
    buttonStatus() {
      const that = this;
      if (that.status == true) {
        return true;
      } else if (that.status == false) {
        return false;
      } else {
        return false;
      }
    },
    chack() {
      const that = this;
      if (that.formdata.dtm_amount == 0 && that.formdata.donate_amount == 0) {
        return false;
      } else {
        return true;
      }
    },
    pay_m() {
      if (this.formdata.pay_type == "Cash") {
        return false;
      } else {
        return true;
      }
    },
    total_amount() {
      if (this.formdata.donate_amount) {
        if (this.formdata.donate_amount < 0) {
          return parseFloat(this.formdata.dtm_amount);
        } else {
          return (
            parseFloat(this.formdata.dtm_amount) +
            parseFloat(this.formdata.donate_amount)
          );
        }
      } else {
        return parseFloat(this.formdata.dtm_amount);
      }
    },
  },
  methods: {
    openPortal(link) {
      this.windowRef = window.open(
        link,
        "Money Recept - Print",
        "width=800,height=1200,left=200,top=200"
      );
    },
    openBio(link) {
      if(this.dtmid){
        this.windowRef = window.open(
        link,
        "Full DTM Information - Print",
        "width=800,height=1200,left=200,top=200"
      );
      }else{
        let variant = "danger";
            this.$bvToast.toast("DTM ID required", {
              title: `Error`,
              variant,
              solid: true,
            });
      }
      
    },
    dtm_month() {
      this.formdata.dtm_amount = 300 * this.month;
    },
    newcashtran() {
      const that = this;
      var userdata = JSON.parse(localStorage.getItem("userData"));
      console.log(userdata);

      if (!that.dtmid == "" && !that.formdata.moneyrecept == "") {
        if (that.status == true) {
          if (that.chack) {
            this.$swal({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, Pay it!",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
              showLoaderOnConfirm: true,
              preConfirm() {
                that.$http
                  .post(`/dtm/newcash/pay/${userdata.id}/`, {
                    moneyrecept: that.formdata.moneyrecept,
                    account: that.formdata.account,
                    number: that.formdata.number,
                    dtm_id: that.dtmid,
                    tran_val: 1,
                    tran_date: that.formdata.tran_date,
                    donate_type: that.formdata.donate_type,
                    pay_type: that.formdata.pay_type,
                    total_amount: that.total_amount,
                    dtm_amount: that.formdata.dtm_amount,
                    donate_amount: that.formdata.donate_amount,
                    userName: userdata.username,
                    ip_address: that.geo.ip,
                    remark: `Cash Payment successfull by ${userdata.username}`,
                    message: `Cash Payment successfull by ${userdata.username}`,
                  })
                  .then((response) => {
                    console.log(response);
                    that.result = response.data.result;
                    that.is_result = true;
                  })
                  .catch((error) => {
                    // this.$swal.showValidationMessage(`Request failed:  ${error}`);
                    console.log(error);
                  });
              },
            }).then((result) => {
              if (result.value) {
                this.$swal({
                  icon: "success",
                  title: "Created!",
                  text: "Payment has been Created.",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                that.cleandata();
              }
            });
          } else {
            let variant = "danger";
            this.$bvToast.toast("DTM amount or Donate amount required", {
              title: `Error`,
              variant,
              solid: true,
            });
          }
        } else {
          let variant = "danger";
          this.$bvToast.toast("Account is Inactive!", {
            title: `Error`,
            variant,
            solid: true,
          });
        }
      } else {
        let variant = "danger";
        this.$bvToast.toast("DTM ID and Trx/Money Recept Number is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    updatetran() {
      const that = this;
      var userdata = JSON.parse(localStorage.getItem("userData"));
      console.log(userdata);
      console.log(that.selected);

      if (!that.selected == "") {
        this.$swal({
          title: "Enter Money Recept Number",
          input: "text",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Pay",
          showLoaderOnConfirm: true,
          preConfirm(number) {
            that.$http
              .post(`/dtm/reg/pay/${userdata.id}/${that.selected}/`, {
                moneyrecept: number,
                userName: userdata.username,
                message: `Cash Payment successfull by ${userdata.username}`,
              })
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                // this.$swal.showValidationMessage(`Request failed:  ${error}`);
                console.log(error);
              });
          },
        }).then((result) => {
          if (result.value) {
            this.$swal({
              title: `Payment Success`,
              // imageUrl: result.value.avatar_url,
              customClass: { confirmButton: "btn btn-primary" },
            });
            that.cleandata();
          }
        });
      } else {
        let variant = "danger";
        this.$bvToast.toast("Transaction Id is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    cleandata() {
      this.dtmid = "";
      this.fullName = "";
      this.mobile1 = "";
      this.dateOfBirth = "";
      this.opening_date = "";
      this.paddress = "";
      this.panding_amount = "";
      this.advance_amount = "";
      this.month = 0;
      this.data = {};
      this.status = null;
      this.selected = null;
      this.options = [];
      this.formdata.moneyrecept = "";
      this.formdata.number = "";
      this.formdata.account = "";
      this.formdata.tran_date = "";
      this.formdata.pay_type = "Cash";
      this.formdata.dtm_amount = 0;
      this.formdata.donate_amount = 0;
      this.getdata();
    },
    dtmreset() {
      this.month = 0;
      this.formdata.dtm_amount = 0;
    },
    gettran() {
      const that = this;
      if (!this.dtmid == "") {
        this.$http
          .get(`/dtm/reg/pay/dtm/${that.dtmid}/`)
          .then((response) => {
            if (response.data.message) {
              that.cleandata();
              let variant = "danger";
              this.$bvToast.toast("User Not Found", {
                title: `Error`,
                variant,
                solid: true,
              });
            } else {
              this.data = response.data;
              this.fullName = response.data.dtm_profile.fullName;
              this.mobile1 = response.data.dtm_profile.mobile1;
              this.dateOfBirth = response.data.dtm_profile.dateOfBirth;
              this.opening_date = response.data.join_date;
              this.free_month = response.data.free_month;
              this.paddress = response.data.dtm_profile.presentAddr + ", " + response.data.dtm_profile.presentCity + ", " + response.data.dtm_profile.presentCountry;
              this.panding_amount = response.data.account.panding_amount;
              this.advance_amount = response.data.account.advance_amount;
              this.status = response.data.dtm_profile.status;
              this.options = [];
              for (let i = 0; i < this.data.trans.length; i++) {
                let datanew = {
                  value: this.data.trans[i].id,
                  text: `Tran Date: ${that.data.trans[i].tran_date} | Tran Id: ${that.data.trans[i].id} ----- PayType: ${that.data.trans[i].pay_type} | Total Amount: ${that.data.trans[i].total_amount}BDT | Monthly Fee: ${that.data.trans[i].dtm_amount}BDT | Extra Donation: ${that.data.trans[i].donate_amount}BDT | Payment status: ${that.data.trans[i].status} | Created By: ${that.data.trans[i].created_by}  `,
                };
                this.options.push(datanew);
              }
            }
          })
          .catch((error) => console.log(error));
      } else {
        let variant = "danger";
        this.$bvToast.toast("Pls Enter Customer Id", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    getdata() {
      this.$http
        .get(`/dtm/reg/pay/dtmlist/${this.dtmid || 0}/`)
        .then((response) => {
          this.predata = response.data;
        })
        .catch((error) => console.log(error));
    },
    getip() {
      axios
        .get("https://get.geojs.io/v1/ip/geo.json")
        .then((response) => {
          this.geo = response.data;
        })
        .catch((error) => console.log(error));
    },

    sendsms(type = "sendDtmID") {
      const that = this;
      var userdata = JSON.parse(localStorage.getItem("userData"));
      console.log(userdata);

      if (!that.dtmid == "") {
        if (that.status == true) {
          if (true) {
            this.$swal({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, Send it!",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
              showLoaderOnConfirm: true,
              preConfirm() {
                that.$http
                  .get(`/dtm/dtmids/2022/${type}/${that.dtmid}/${that.dtmid}`)
                  .then((response) => {
                    console.log(response);
                    let variant = "success";
                    that.$bvToast.toast(`SMS send ${response.data.res.api_response_message}`, {
                      title: `Success`,
                      variant,
                      solid: true,
                    });
                  })
                  .catch((error) => {
                    let variant = "danger";
                    that.$bvToast.toast("SMS Send Failed", {
                      title: `Error`,
                      variant,
                      solid: true,
                    });
                    console.log(error);
                  });
              },
            }).then((result) => {
              if (result.value) {
                that.$swal({
                  icon: "success",
                  title: "Created!",
                  text: "SMS Send Successfully.",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              }
            });
          } else {
            let variant = "danger";
            that.$bvToast.toast("SMS send Failed", {
              title: `Error`,
              variant,
              solid: true,
            });
          }
        } else {
          let variant = "danger";
          that.$bvToast.toast("Account is Inactive!", {
            title: `Error`,
            variant,
            solid: true,
          });
        }
      } else {
        let variant = "danger";
        that.$bvToast.toast("DTM ID is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
  },
  created() {
    this.getdata();
    this.getip();
  },
};
</script>