<template>
  <div>
    <div class="text-right mb-1 mr-1">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="btn-icon rounded-circle"
        @click="getdatastatus()"
      >
        <feather-icon icon="RefreshCcwIcon" />
      </b-button>
    </div>
    <b-table :items="items" :fields="fields" striped responsive>
      <template #cell(show_details)="row">
        <b-form-checkbox
          v-model="row.detailsShowing"
          plain
          class="vs-checkbox-con"
          @change="row.toggleDetails"
        >
          <span class="vs-checkbox">
            <span class="vs-checkbox--check">
              <i class="vs-icon feather icon-check" />
            </span>
          </span>
          <span class="vs-label">{{
            row.detailsShowing ? "Hide" : "Show"
          }}</span>
        </b-form-checkbox>
      </template>

      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col md="4" class="mb-1">
              <strong>Customer ID : </strong
              >{{ row.item.customer_id || "DTM Pay" }}
            </b-col>
            <b-col md="4" class="mb-1">
              <strong>Tran/Money Recept : </strong>
              {{ row.item.gateway_tran_id }}
            </b-col>
            <b-col md="4" class="mb-1">
              <strong>Pay Type: : </strong>{{ row.item.pay_type }}
            </b-col>
            <b-col md="4" class="mb-1">
              <strong>DTM ID : </strong
              >{{ row.item.dtm_id || "Registration Pay" }}
            </b-col>
            <b-col md="4" class="mb-1">
              <strong>Created By : </strong>{{ row.item.created_by }}
            </b-col>
            <b-col md="4" class="mb-1">
              <strong>Payment verify : </strong>
              <b-badge
                variant="success"
                class="badge-glow"
                v-if="row.item.is_auth"
              >
                Success
              </b-badge>
              <b-badge variant="danger" class="badge-glow" v-else>
                Pending
              </b-badge>
            </b-col>
          </b-row>

          <b-button
            size="sm"
            variant="outline-secondary"
            @click="row.toggleDetails"
          >
            Hide Details
          </b-button>
        </b-card>
      </template>

      <template #cell(avatar)="data">
        <b-avatar :src="data.value" />
      </template>

      <template #cell(pay_status)="data">
        <b-badge :variant="pay_status[1][data.value]">
          {{ pay_status[0][data.value] }}
        </b-badge>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        "show_details",
        "tran_date",
        "id",
        "total_amount",
        { key: "pay_status", label: "pay_status" },
      ],
      items: [],
      pay_status: [
        {
          1: "Success",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-success",
          2: "light-primary",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  methods: {
    getdatastatus() {
      var userdata = JSON.parse(localStorage.getItem("userData"));
      this.$http
        .get(`/dtm/reg/pay/trandata/${userdata.username}/`)
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => console.log(error));
    },
  },
  created() {
    this.getdatastatus();
  },
};
</script>